import '../../../style/Login/login.css';
import { useForm } from 'react-hook-form';

const RegisterLogin = ({ handleContinue, goToLogin }) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data.password !== data.repeat_password) {
      setError("repeat_password", {
        type: "manual",
        message: "Las contraseñas no coinciden",
      });
      return;
    }

    handleContinue({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password
    }).then((result) => {
      if (!result) {
        handleSubmit(handleContinue);
      } else {
        setError("email", {
          type: "manual",
          message: "El email ingresado ya se encuentra en uso",
        });
      }
    }).catch((error) => {
      console.log("Error:", error);
    });
  };

  return (
    <div className="RegisterLogin">
      <p className="titleLoginRegister">Registrarme</p>

      <form className="registerForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="containerEmailLogin">
          <div className={`inputContainer ${errors.first_name ? "has-error" : ""}`}>
            <input 
              {...register('first_name', { required: '*El campo de nombre es obligatorio' })} 
              type="text" 
              className={`login-input ${errors.first_name ? 'input-error' : ''}`} 
              placeholder="Nombre" 
            />
            <p className="error-message">{errors.first_name?.message}</p>
          </div>

          <div className={`inputContainer ${errors.last_name ? "has-error" : ""}`}>
            <input 
              {...register('last_name', { required: '*El campo de apellido es obligatorio' })} 
              type="text" 
              className={`login-input ${errors.last_name ? 'input-error' : ''}`} 
              placeholder="Apellido" 
            />
            <p className="error-message">{errors.last_name?.message}</p>
          </div>

          <div className={`inputContainer ${errors.email ? "has-error" : ""}`}>
            <input 
              {...register('email', { required: '*El campo de email es obligatorio' })} 
              type="email" 
              className={`login-input ${errors.email ? 'input-error' : ''}`} 
              placeholder="Email" 
            />
            <p className="error-message">{errors.email?.message}</p>
          </div>

          <div className={`inputContainer ${errors.password ? "has-error" : ""}`}>
            <input 
              {...register('password', { required: '*El campo de contraseña es obligatorio' })} 
              type="password" 
              className={`login-input ${errors.password ? 'input-error' : ''}`} 
              placeholder="Contraseña" 
            />
            <p className="error-message">{errors.password?.message}</p>
          </div>

          <div className={`inputContainer ${errors.repeat_password ? "has-error" : ""}`}>
            <input 
              {...register('repeat_password', { 
                required: '*El campo de repetir contraseña es obligatorio',
                validate: (value) =>
                  value === watch("password") || "Las contraseñas no coinciden"
              })} 
              type="password" 
              className={`login-input ${errors.repeat_password ? 'input-error' : ''}`} 
              placeholder="Repetir contraseña" 
            />
            <p className="error-message">{errors.repeat_password?.message}</p>
          </div>
        </div>

        <div className="checkboxContainer">
          <label className="checkboxLabel">
            <input type="checkbox" />
            Quiero recibir recomendaciones de espectáculos
          </label>
          <label className="checkboxLabel">
            <input type="checkbox" />
            Acepto los <a href="#">términos y condiciones</a>
          </label>
        </div>

        <button type="submit" className="apiLoginButton">CONTINUAR</button>

        <div className="backToLogin">
          <button className="backToLoginButton" onClick={goToLogin}>Ya tengo cuenta</button>
        </div>
      </form>
    </div>
  );
}

export default RegisterLogin;
