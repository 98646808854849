import { Logo } from '../../img';
import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import axios from 'axios';
import { endpointUser1 } from '../../endpoints';
import '../../style/Users/profile.css';
import ButtonSelected from '../Buttons/ButtonSelected';
import { Link } from 'react-router-dom';
import { ClickLoginNewsLetter, backgroundProfile } from '../../img';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';

const INTERESTS = [
    "Música", "Cine", "Teatro", "Carnaval", "Fiestas", "Comedia", "Drama", "Thriller",
    "Documental", "Acción", "Aventura", "Infantil", "Animación", "Ciencia ficción",
    "Bélico", "Crimen", "Musical", "Romance"
];

const User = () => {
    const token = localStorage.getItem('sessionToken');
    const [events, setEvents] = useState([]);
    const [user, setUser] = useState("");
    const [loading, setLoading] = useState(false);
    const [newsLetter, setNewsLetter] = useState(false);
    const [selectedInterests, setSelectedInterests] = useState([]);

    const fetchFavoriteEvents = async () => {
        setLoading(true);
        try {
            await axios.post(endpointUser1, { token }).then((res) => {
                setEvents(res.data.events);
                setUser(res.data.username);
                setSelectedInterests(res.data.interests || []);
            });
        } catch (error) {
            message.error('Error al obtener datos del usuario');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFavoriteEvents();
    }, []);

    const toggleInterest = (interest) => {
        setSelectedInterests((prev) =>
            prev.includes(interest)
                ? prev.filter((item) => item !== interest)
                : [...prev, interest]
        );
    };

    return (
        <>
            <Header />

            {/* Contenedor con la imagen de fondo */}
            <div className="profileBackground">
                <div className="userProfileContainer">
                    <h1 className="profileTitle">Editar mi perfil</h1>

                    <div className="profileSections">
                        <div className="profileCard">
                            <h3>Mis datos</h3>
                            <input type="text" className="profileInput" placeholder="Nombre" />
                            <input type="text" className="profileInput" placeholder="Apellido" />
                            <input type="email" className="profileInput" placeholder="Email" />
                            <button className="profileSaveButton">GUARDAR</button>
                        </div>

                        <div className="profileCard">
                            <h3>Cambiar contraseña</h3>
                            <input type="password" className="profileInput" placeholder="Actual" />
                            <input type="password" className="profileInput" placeholder="Nueva" />
                            <input type="password" className="profileInput" placeholder="Confirmación" />
                            <button className="profileSaveButton">GUARDAR</button>
                        </div>
                    </div>

                    <div className="interestsCard">
                        <h3>Mis intereses</h3>
                        <div className="interestsGrid">
                            {INTERESTS.map((interest, index) => (
                                <button
                                    key={index}
                                    className={`interestButton ${selectedInterests.includes(interest) ? "selected" : ""}`}
                                    onClick={() => toggleInterest(interest)}
                                >
                                    {interest}
                                </button>
                            ))}
                        </div>
                        <button className="profileSaveButton">GUARDAR</button>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default User;
